// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require cocoon
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('font-awesome/css/font-awesome.css')
require("jquery")
require('cocoon-rails/src/cocoon')
require('chart.js/dist/Chart')
require('materialize-css/dist/js/materialize')
require('../stylesheets/application')
require("trix")
require("@rails/actiontext")
require('packs/dashboards')
require('packs/relateds')
require('packs/banner')
require('packs/users')
import 'packs/select2'
require('packs/resources')

  // disable rich text attachment
  window.addEventListener("trix-file-accept", function(event) {
    event.preventDefault();
    alert("No se pueden subir archivos!")
  })

$(document).on('turbolinks:load',function(){

  M.AutoInit();  
  $('.carousel').carousel({
    dist: -1,
    numVisible: -1,
    fullWidth: false,
    padding: 500,
  });

  autoplay();

  function autoplay() {
    $('.carousel').carousel('next');
    setTimeout(autoplay, 5000);
  }

  $(".dropdown-trigger").dropdown({
    coverTrigger: false
  });

  $('tab').on('click', function(e){
    e.preventDefault()
  })

  $('.tabs').tabs()

  $('.dropdown-trigger').on('click', function(e){
    e.preventDefault()
  });

  $('.sidenav-trigger').on('click', function(e){
    e.preventDefault()
    $('.sidenav').sidenav('open');
  });

  $(".sidenav ul.collapsible").hover(
    function(e){
      $(e.currentTarget).find('.collapsible-body').show()
    },
    function(e){
      $(e.currentTarget).find('.collapsible-body').hide()
    }
  );

});


$(document).on("turbolinks:before-cache", function() {
  if ($('select').length) {
    $('select').formSelect("destroy")
  }
  $(".dropdown-trigger").dropdown({
    coverTrigger: false
  });
});


window.alert_notification = function(notify){
  return M.toast({html: notify})
}

window.notice_notification = function(notice){
  return M.toast({html: notice})
}