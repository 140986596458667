let index_select_states;

$(document).on('turbolinks:load turbolinks:before-cache', function(){
  index_select_states = $('#select-search-bar').html();
  // Search after select
  $('form.resource_search select').on('change', function(){
    $('form.resource_search input.btn').click()
  })

  // Search after select new owner of resource
  $('form.owner-select select').on('change', function(){
    $(this).closest('form').submit()
  })

  $('.tooltipped').on('click', function(){
    $(this).tooltip('open')
  })

  $('a.subtopic-modal-trigger').on('click', function(e){
    e.preventDefault()
    $('#subtopic-modal').modal("open")
  })

  function regroupList() {
    var selected = $('select#resource_category option:selected').val()
    var user_role = $('.label_for_type').attr('id')

    if (window.location.pathname != '/resources/new') {
      checkValueSelected(selected, user_role)
    }
    // Change headers if resource is 'ficha'
    if (selected == 'ficha') {
      $('.content-objective').text('Objetivos de Aprendizajes Propuestos')
      $('.content-guideline').text('Pauta')
    } else {
      $('.content-objective').text('Descripción')
      $('.content-guideline').text('Guía de uso')
    }

    if ($('#resource_objective').val() != '' || $('#resource_guideline').val() != '') {
      result = confirm('Desea sobreescribir los cambios')
      if (result) {
        $('#resource_objective').val('')
        $('#resource_guideline').val('')  
      }
    }
  }

  $('#form-resource select#resource_category').on('change', function() {
    regroupList()
  })

  $('select').formSelect()

  function checkValueSelected(selected, user_role){
    if (user_role == "admin" || selected == 'ficha' || selected == 'ppt') {
      $('.related-resources').fadeIn(500)
      $('form .row.resource div').first().removeClass().addClass('col m8 s12')
      $('.related-resources-card').show()
    } else {
      $('.related-resources').fadeOut(500)
      $('form .row.resource div').first().removeClass().addClass('col m12 s12')
      $('.related-resources-card').hide()
    }
  }

  var selected = $('select#resource_category option:selected').val()
  if (selected == 'ficha') {
    $('.content-objective').text('Objetivos de Aprendizajes Propuestos')
    $('.content-guideline').text('Pauta')
  } else {
    $('.content-objective').text('Descripción')
    $('.content-guideline').text('Guía de uso')
  }


  M.FloatingActionButton.init($('.fixed-action-btn'), {
    hoverEnabled: false
  });

  // #####################################################
  var states = $('#select-search').html();
  $('#resource_topic_id').change(function() {
    $('.sub_topic').show()
    var topic = $('#resource_topic_id option:selected').text();
    var options = $(states).filter("optgroup[label='"+ topic +"']").html();
    if (options) {
      $('#select-search').html(options);
    } else {
      $('#select-search').html("<option value='' selected='selected'>No Hay un <strong>tema</strong> elegido</option>"); 
    }
    $('#select-search').prepend("<option value='' selected='selected'>Sub-Tema</option>")
    $('#select-search').formSelect();
  });

  // ########################################################

  $('.sub_topic').show()
  var topic = $('#resource_topic_id option:selected').text();
  var options = $(states).filter("optgroup[label='"+ topic +"']").html();
  if (options) {
    $('#resource_sub_topic_id').html(options);
  } else {
    $('#resource_sub_topic_id').html("<option value='' selected='selected'>No Hay un <strong>tema</strong> elegido</option>"); 
  }
  $('#resource_sub_topic_id').prepend("<option value='' selected='selected'>Sub-Tema</option>")
  $('#resource_sub_topic_id').formSelect();

  var selected = $('select#resource_category option:selected').val()
  var user_role = $('.label_for_type').attr('id')

  if (window.location.pathname != '/resources/new') {
    checkValueSelected(selected, user_role)
  }
  if ($('textarea').length) {
    M.textareaAutoResize($('#resource_objective'))
    M.textareaAutoResize($('#resource_guideline'))
    M.textareaAutoResize($('#resource_comments'))
    M.textareaAutoResize($('#resource_admin_comments'))
  }


  if ($('#resource_admin_comments').val() == '') {
    $('.comments-btn').css("background-color","#4caf50 ")
    $('.comments-btn').text("publicar recurso")
  } else {
    $('.comments-btn').css("background-color","#ffc107")
    $('.comments-btn').css("width","201px")
    
    $('.comments-btn').text("realizar observaciones")
  }

  $('#resource_admin_comments').on('input',function(){
    if ($('#resource_admin_comments').val() == '') {
      $('.comments-btn').css("background-color","#4caf50 ")
      $('.comments-btn').text("publicar recurso")
    } else {
      $('.comments-btn').css("background-color","#ffc107")
      $('.comments-btn').css("width","201px")

      $('.comments-btn').text("realizar observaciones")
    }
  })

  $("a.show-modal").on("click", function(e){
    e.preventDefault()
    $("#modal-jumbotron").modal('open')
  })

})


$(document).on("turbolinks:before-cache", function() {
  $('.subtopic-modal-trigger').on('click', function(e){
    e.preventDefault()
    $('#subtopic-modal').modal("open")
  })
  
  if ($('#resource_admin_comments').text() == '') {
    $('.comments-btn').css("background-color","#4caf50 ")
    $('.comments-btn').text("publicar recurso")
  } else {
    $('.comments-btn').css("background-color","#ffc107")
    $('.comments-btn').css("width","201px")

    $('.comments-btn').text("realizar observaciones")
  }

  if ($('textarea').length) {
    M.textareaAutoResize($('#resource_objective'))
    M.textareaAutoResize($('#resource_guideline'))
    M.textareaAutoResize($('#resource_comments'))
    M.textareaAutoResize($('#resource_admin_comments'))
  }

  $("a.show-modal").on("click", function(e){
    e.preventDefault()
    $("#modal-jumbotron").modal('open')
  })

});

// Filter sub-topics options
$(document).on('change', '#q_topic_id_eq', function() {
  $('.sub_topic').show()
  var index_select_topic = $('#q_topic_id_eq option:selected').text();
  var index_select_options = $(index_select_states).filter("optgroup[label='"+ index_select_topic +"']").html();
  if (index_select_options) {
    $('#select-search-bar').html(index_select_options);
  } else {
    $('#select-search-bar').html(index_select_states); 
  }
  if($("#select-search-bar").children('option:contains("Subtema")').length <= 1) {
    $('#select-search-bar').prepend("<option value='' selected='selected'>Subtema</option>")
  }
  $('#select-search-bar').formSelect();
  $('form.resource_search input.btn').click()
});