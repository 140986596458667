$(document).on('turbolinks:load',function(){
  $("a.modal-close").on('click', function(event) {
    event.preventDefault()
    $('#send-email-modal').modal('close')
  })

  $("a.modal-trigger").on('click', function(event) {
    event.preventDefault()
    $('#send-email-modal').modal('open')
  })
  $("#massive-mail-btn").on('click', function(e){
    e.preventDefault() 
    $("#massive-mail-modal").modal("open")
  })

  $(".extra-questions").hide()

  $('#user_reason').on('change', function(){
    if ($("#user_reason option:selected").index() == 1) {
      $(".extra-questions").show()
    } else {
      $(".extra-questions").hide()
    }
  })

})

$(document).on("turbolinks:before-cache", function() {
  $(".extra-questions").hide()
 })