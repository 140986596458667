$(document).on('turbolinks:load', function(){
    if (window.location.pathname == '/dashboard') {
        $.ajax({
            url: window.location.origin + '/charts',
            type: 'GET',
            dataType: 'json',
            success: function(data){
                generate_chart(data.users, document.getElementById('usersChart'), 'Registro de Usuarios Por Mes')
                generate_chart(data.user_sessions.from_this_week, document.getElementById('sessionsChart'), "Visitas de esta semana")
                generate_chart(data.user_sessions.by_month, document.getElementById('sessionsChartByMonth'), "Visitas Mensuales")
                generate_chart(data.user_sessions.by_year, document.getElementById('sessionsChartByYear'), "Visitas Anuales")
                $("i.fa.fa-circle-o-notch.fa-spin.fa-2x").remove()
            }
        })
    }
    function generate_chart(data, element, label){
        var date = []
        var sessions = []
        var largest= 0;

        for (var i = 0; i < data.length; i++){
            date.push(data[i].date)
    
            sessions.push(Number(data[i].sessions))
        }

        for (i=0; i<=sessions.length;i++){
            if (sessions[i]>largest) {
                largest = sessions[i];
            }
        }
        sessions.push(largest+2)
        var ctx = element.getContext('2d');
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: date,
                datasets: [{
                    label: label,
                    data: sessions,
                    backgroundColor: '#bbdefb'
                }]
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                title: {
                    display: true,
                    text: label
                },
                legend: {
                    display: false
                }
            }
        });
    }
})

