// Search through related resources
$(document).on('turbolinks:load',function(){
  $('#submit-relateds-search').on('click', function(event){
    event.preventDefault()
     $.ajax({
        url: document.location.origin + '/search',
        type: 'GET',
        dataType: 'script',
        data: {
          type: "related",
          resource: {
            id: $(".related-resource-search").attr('id')
          },
          search_resource: {
            sub_topic_name: $("#related_resource_sub_topic").val()
          }
        },
    })
  })

// Search through unrelated resources
  $('#submit-unrelateds-search').on('click', function(event){
    event.preventDefault()
     $.ajax({
        url: document.location.origin + '/search',
        type: 'GET',
        dataType: 'script',
        data: {
          type: "unrelated",
          resource: {
            id: $(".unrelated-resource-search").attr('id')
          },
          search_resource: {
            sub_topic_name: $("#unrelated_resource_sub_topic").val()
          }
        },
    })
  })
})