import $ from 'jquery';
import 'select2/dist/js/select2.min'
const countries = require('packs/countries_es.json')

$(document).on('turbolinks:load ajax:complete',function(){
  $("#select-search").select2()  
  $("#select-search-bar").select2()
  
  // Search after select
  $("#select-search-bar").on('select2:select', function () {
    $('form.resource_search input.btn').click()
  });

  $('#user_country').select2({
    data: countries
  });
})
